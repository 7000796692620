<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col span="5">
          <a-form-item label="批量操作批次">
            <a-input
              v-decorator="['operation_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col span="5">
          <a-form-item label="批量操作类型">
            <a-select
              v-decorator="['operation_type']"
              allow-clear
              :loading="loadingOperationTypeOptions"
              @focus="fetchOperationTypeOptions"
              show-search
              option-filter-prop="children"
            >
              <a-select-option
                v-for="option in operationTypeOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="4" v-show="expand">
          <a-form-item label="状态">
            <a-select
              v-decorator="['status']"
              allow-clear
              :loading="loadingStatusOptions"
              @focus="fetchStatusOptions"
            >
              <a-select-option
                v-for="option in statusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="操作时间">
            <a-range-picker v-decorator="['operation_date']" />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import {
  findBatchOperationTypeOptions,
  findBatchOperationStatusOptions
} from '@/api/common_options'

export default {
  name: 'SearchBatchOperationRecord',
  data() {
    return {
      expand: false,
      operationTypeOptions: [],
      statusOptions: [],
      loadingOperationTypeOptions: false,
      loadingStatusOptions: false,
      form: this.$form.createForm(this, { name: 'batch_operation_record_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载批量操作类型选项
    fetchOperationTypeOptions() {
      if (this.operationTypeOptions.length === 0) {
        this.loadingOperationTypeOptions = true
        findBatchOperationTypeOptions().then(res => {
          if (res.code === 0) {
            this.operationTypeOptions = res.data
            this.loadingOperationTypeOptions = false
          }
        })
      }
    },

    // 加载批量操作状态选项
    fetchStatusOptions() {
      if (this.statusOptions.length === 0) {
        this.loadingStatusOptions = true
        this.statusOptions = findBatchOperationStatusOptions()
        this.loadingStatusOptions = false
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        operation_date_begin: formatRangeBeginDate(fieldsValue.operation_date),
        operation_date_end: formatRangeEndDate(fieldsValue.operation_date)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
