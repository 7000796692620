import request from '@/utils/request'

// 查询批量操作记录
export function findBatchOperationRecords(params) {
  return request({
    url: `/batch_operation_records`,
    method: 'get',
    params: params
  })
}

// 查询批量操作记录详情
export function findBatchOperationRecord(id) {
  return request({
    url: `/batch_operation_records/${id}`,
    method: 'get'
  })
}

// 导出批量操作记录
export function exportBatchOperationRecords(data) {
  return request({
    url: `/batch_operation_records/export`,
    method: 'post',
    data
  })
}
